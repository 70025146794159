import { useQuery } from '@tanstack/react-query';
import { request } from '../util';
import { AdditionalQueryOptions, Organization } from '../types';

/**
 * Returns all the organizations for this user
 */
export function useOrganizationsQuery(options: AdditionalQueryOptions<Organization[], ['organizations']> = {}) {
  return useQuery({
    ...options,
    queryKey: ['organizations'],
    async queryFn() {
      return request<Organization[]>({
        pathname: `/api/organizations`,
      });
    },
  });
}

export function useCurrentOrganizationQuery(
  options: AdditionalQueryOptions<Organization | null, ['organization', string]> = {},
) {
  return useQuery({
    ...options,
    queryKey: ['organization', 'current'],
    async queryFn() {
      return request<Organization>({
        pathname: `/api/organizations`,
      });
    },
  });
}
